<!--  -->
<template>
  <div class="approval-detail">
    <div class="values card">
      <ul class="value-ul">
        <li class="item">
          <div class="label">关联审批单号</div>
          <div class="value">{{ info.applyCode }}</div>
        </li>

        <li class="item">
          <div class="label">申请车辆</div>
          <div class="value">{{ info.platingNumber }}</div>
        </li>
        <li class="item">
          <div class="label">用车人</div>
          <div class="value">{{ info.staffName }}</div>
        </li>

        <li class="item">
          <div class="label">实际开始时间</div>
          <div class="value">{{ info.beginTime }}</div>
        </li>
        <li class="item">
          <div class="label">实际还车时间</div>
          <div class="value">{{ info.endTime }}</div>
        </li>
        <li class="item">
          <div class="label">去向</div>
          <div class="value">{{ info.origin || "--" }}</div>
        </li>

        <li class="item">
          <div class="label">停车位置</div>
          <div class="value">{{ info.destination }}</div>
        </li>

        <li class="item">
          <div class="label">结束油量%</div>
          <div class="value">{{ info.endFuelQuantity || "--" }}</div>
        </li>
        <li class="item">
          <div class="label">结束里程</div>
          <div class="value">{{ info.endMileage || "--" }}</div>
        </li>
        <li class="item">
          <div class="label">结束备注</div>
          <div class="value">{{ info.destinationRemark || "--" }}</div>
        </li>

        <li class="item">
          <div class="label">借用油卡</div>
          <div class="value">{{ info.cardType | formatCardType }}</div>
        </li>

        <li class="item">
          <div class="label">油卡名称</div>
          <div class="value">
            {{ info.cardUseVO ? info.cardUseVO.cardName : "--" }}
          </div>
        </li>

        <li class="item">
          <div class="label">本次支付金额</div>
          <div class="value">
            {{ info.cardUseVO ? info.cardUseVO.useMoney : "--" }}
          </div>
        </li>
        <li class="item">
          <div class="label">卡内剩余金额</div>
          <div class="value">
            {{ info.cardUseVO ? info.cardUseVO.balanceMoney : "--" }}
          </div>
        </li>

        <li class="item">
          <div class="label">结束备注</div>
          <div class="value">{{ info.destinationRemark || "--" }}</div>
        </li>

        <li class="item">
          <div class="label">结束凭证</div>
          <img
            :src="item"
            alt=""
            class="image"
            v-for="(item, index) in info.destinationImgUrls"
            :key="index"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { returnCarInfo } from "@/api/cart/manage/index.js";

export default {
  data() {
    return {
      info: {},
    };
  },
  name: "approvalDetail",
  components: {},
  computed: {},
  filters: {
    formatCardType(val) {
      const rules = {
        0: "否",
        1: "是",
      };
      return rules[val];
    },
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      returnCarInfo({
        bizCode: this.$route.query.bizCode,
      }).then((res) => {
        this.info = res.data;
        this.info.platingNumber = this.$route.query.platingNumber;
        this.info.staffName = this.$route.query.staffName;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-detail {
  min-height: 100%;
  background: #fafafa;
  width: 7.5rem;
  position: relative;
  padding: 1px;
  .card {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: #fff;
    margin-bottom: 0.3rem;
    border: 1px solid transparent;
  }
  .values {
    .value-ul {
      .item {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.3rem 0;
        .label {
          height: 0.37rem;
          font-size: 0.26rem;
          color: #999999;
          line-height: 0.37rem;
          margin-bottom: 0.09rem;
        }
        .img-value {
          width: 2rem;
          height: 2rem;
        }
        .file-box {
          margin-bottom: 0.2rem;
          .file-value {
            border-bottom: 1px solid #0094ee;
            color: #0094ee;
          }
        }

        .value {
          font-size: 0.3rem;
          color: #333333;
          line-height: 0.42rem;
          word-break: break-all;
        }
      }
      .item:last-of-type {
        border: none;
      }
    }
  }
}
.image {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.2rem;
}
</style>
